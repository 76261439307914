<template>
  <div class="q-header">
    <!--this is the dialog for add trees-->
    <q-dialog v-model="this.addRemoveTrees" persistent>
      <q-card
        v-if="this.addTreeList.length > 0"
        class="row bg-white text-blue-grey-9 q-mr-md"
        style="width: 300px"
      >
        <q-card-section>
          <div class="text-h6">Add Trees</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Add
          {{ this.addTreeList.length }} trees to your project?
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn
            flat
            label="Yes"
            color="primary"
            @click="this.addTreesToProject()"
            v-close-popup
          />
          <q-btn
            flat
            label="Cancel"
            color="red-5"
            v-close-popup
            @click="this.clearSelectedTrees(3)"
          />
        </q-card-actions>
      </q-card>

      <q-card
        v-if="this.removeTreeList.length > 0"
        class="bg-white text-blue-grey-9"
        style="width: 300px"
      >
        <q-card-section>
          <div class="text-h6">Remove Trees</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Remove
          {{ this.removeTreeList.length }} trees from your project?
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn
            flat
            label="Yes"
            color="primary"
            @click="this.removeTreesFromProject()"
            v-close-popup
          />
          <q-btn
            flat
            label="Cancel"
            color="red-5"
            v-close-popup
            @click="this.clearSelectedTrees(2)"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <div id="map" style="z-index: 99"></div>
    <div
      v-if="activeProject.role_name == 'Project Leader'"
      class="bg-white q-pa-sm text-center"
      style="width: 85px; height: 58px; z-index:100; position:absolute; top: 10px; left:43px; font-size: 11px"
    >
      ADD/REMOVE TREES
      <q-btn flat padding="none" icon="info" color="primary"
        ><q-menu
          ><div class="q-pa-md">
            Use polygon tool to draw a box around the trees you want to add or
            remove in your project. Double click to complete and follow prompts.
          </div>
        </q-menu>
      </q-btn>
    </div>
    <div
      style="z-index:100; position:absolute; top: 10px; right:10px"
      v-if="activeProject.role_name == 'Project Leader'"
    >
      <q-btn
        v-if="!showSave"
        color="white"
        text-color="black"
        size="sm"
        label="Edit"
        icon="edit"
        padding="sm"
        stack
        @click="editProjectBoundary()"
      ></q-btn>
      <q-btn
        color="white"
        text-color="primary"
        size="sm"
        label="Save"
        padding="sm"
        class="q-ml-xs"
        icon="save"
        stack
        v-if="showSave"
        @click="saveProjectBoundary()"
      ></q-btn>
      <q-btn
        color="white"
        text-color="amber"
        size="sm"
        label="cancel"
        padding="sm"
        class="q-ml-xs"
        icon="cancel"
        stack
        @click="this.cancelProjectBoundary()"
        v-if="showSave"
      ></q-btn>
    </div>
  </div>
</template>

<script>
import mapboxgl from '/node_modules/mapbox-gl/dist/mapbox-gl.js';
import MapboxDraw from '/node_modules/@mapbox/mapbox-gl-draw';
import '/node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

let mapbox = {
  map: '',
  geolocate: '',
  popup: '',
  draw: '',
  drawPolygon: '',
};

export default {
  name: 'Map',
  components: {},
  computed: {
    treesData() {
      let trees = this.$store.state.treesStore.treesGeoJson;
      //loop through trees - to resymbolize.  If trees are in proj = red, else blue.
      for (let i = 0; i < trees.features.length; i++) {
        let project = parseInt(this.$router.currentRoute.value.params.index);
        let treeProj = trees.features[i].properties.tree_in_project;
        let ind = treeProj.map((e) => e.project_id).indexOf(project);
        if (ind > -1) {
          trees.features[i].properties.symbology = 1;
        } else {
          trees.features[i].properties.symbology = 0;
        }
      }
      return trees;
    },
    addSelectedTrees() {
      let selectedTrees = this.$store.state.treesStore.selectedTreesGeoJson;
      //loop through trees - to resymbolize.  If trees are in proj = red, else blue.
      for (let i = 0; i < selectedTrees.features.length; i++) {
        let project = parseInt(this.$router.currentRoute.value.params.index);
        let treeProj = selectedTrees.features[i].properties.tree_in_project;
        let ind = treeProj.map((e) => e.project_id).indexOf(project);
        if (ind > -1) {
          selectedTrees.features[i].properties.symbology = 2;
        } else {
          selectedTrees.features[i].properties.symbology = 3;
        }
      }
      console.log('selected trees: ' + selectedTrees);
      return selectedTrees;
    },
    addTreeList() {
      let newAddList = this.addSelectedTrees.features.filter(function(el) {
        return el.properties.symbology == 3;
      });
      return newAddList;
    },
    removeTreeList() {
      let newRemoveList = this.addSelectedTrees.features.filter(function(el) {
        return el.properties.symbology == 2;
      });
      return newRemoveList;
    },
    addRemoveTrees() {
      let dialog = false;
      if (this.addTreeList.length == 0 && this.removeTreeList.length == 0) {
        dialog = false;
      } else {
        dialog = true;
      }
      return dialog;
    },
    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
    goToLatLon() {
      return this.$store.state.treesStore.goToLatLon;
    },
  },
  watch: {
    // zoom to project after mounted
    $route() {
      let bboxArray = this.getProjectBoundingBox();
      let bbox = bboxArray[0];
      let geojson = bboxArray[1];
      mapbox.map.fitBounds(bbox, { padding: 20 });
      var source = mapbox.map.getSource('project-boundary');
      source.setData(geojson);
    },
    addSelectedTrees() {
      var source = mapbox.map.getSource('add-trees');
      source.setData(this.addSelectedTrees);
    },
  },
  props: ['height'],

  data() {
    return {
      prompt: false,
      showSave: false,
    };
  },
  mounted() {
    //since I need a different size map in various components I am passing a height prop and
    //resizing the map container on mounted. ie:  'calc(100vh - 200px)'
    document.getElementById('map').style.height = this.height;
    this.createMap();
    /*this.$q.notify({
      message:
        "Looks like you don''t have any trees in your project yet.  Would you like to see instructions for adding a tree?.",
      color: 'white',
      actions: [
        {
          color: 'primary',
          label: 'Show Me',
          handler: () => {
            //do something else
          },
        },
      ],
    });*/
  },
  methods: {
    getProjectBoundingBox() {
      console.log('starts');
      // make geojson from polygon string
      let polygonString = this.activeProject.polygon_text;
      console.log(polygonString);
      // Extract the coordinates substring from the polygon string
      const coordinatesString = polygonString.substring(
        polygonString.indexOf('((') + 2,
        polygonString.lastIndexOf('))')
      );
      // Split the coordinates substring into an array of coordinate strings
      const coordinateStrings = coordinatesString.split(',');
      // Convert each coordinate string to an array of latitude and longitude pairs
      const coordinates = coordinateStrings.map((coord) => {
        const [longitude, latitude] = coord.trim().split(' ');
        return [parseFloat(latitude), parseFloat(longitude)];
      });
      var geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        ],
      };
      var bbox = turf.bbox(geojson);
      return [bbox, geojson];
    },
    createMap() {
      let bboxArray = this.getProjectBoundingBox();
      let bbox = bboxArray[0];
      let geojson = bboxArray[1];
      // First, create a new Mapbox map and set the center and zoom level
      mapboxgl.accessToken =
        'pk.eyJ1IjoidG5jbWFwYm94IiwiYSI6ImNsYmZpNzE0MDA2aHozbm1wazV1aWp3NHUifQ.7plkZIxeS9mzUXB06i-CLg';

      mapbox.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        bounds: bbox,
        maxBounds: [
          // https://spatialreference.org/ref/epsg/4326/
          // XXX: JavaScript or MapBox precision remains a bit of a problem.
          [-179.999998, -89.999998], // southwest corner.
          [179.999998, 89.999998], // northeast corner.
        ],
      });
      let _this = this;
      mapbox.map.on('load', function() {
        mapbox.map.fitBounds(bbox, { padding: 20 });
        mapbox.map.addSource('project-boundary', {
          type: 'geojson',
          data: geojson,
        });

        mapbox.draw = new MapboxDraw({
          displayControlsDefault: false,
          // Select which mapbox-gl-draw control buttons to add to the map.
          controls: {
            polygon: true,
            trash: true,
          },
          styles: [
            // these are the default styles, adding them here enables you to modify them
            // ACTIVE (being drawn)
            // line stroke
            {
              id: 'gl-draw-line',
              type: 'line',
              filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['==', 'active', 'true'],
              ],
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
              paint: {
                'line-color': 'yellow',
                'line-dasharray': [0.2, 2],
                'line-width': 2,
              },
            },
            // polygon fill
            {
              id: 'gl-draw-polygon-fill',
              type: 'fill',
              filter: [
                'all',
                ['==', '$type', 'Polygon'],
                ['==', 'active', 'true'],
              ],
              paint: {
                'fill-color': 'yellow',
                'fill-outline-color': 'yellow',
                'fill-opacity': 0.1,
              },
            },
            // polygon mid points
            {
              id: 'gl-draw-polygon-midpoint',
              type: 'circle',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', 'meta', 'midpoint'],
              ],
              paint: {
                'circle-radius': 3,
                'circle-color': 'black',
              },
            },
            // polygon outline stroke
            // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
            {
              id: 'gl-draw-polygon-stroke-active',
              type: 'line',
              filter: [
                'all',
                ['==', '$type', 'Polygon'],
                ['==', 'active', 'true'],
              ],
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
              paint: {
                'line-color': 'yellow',
                'line-dasharray': [0.2, 2],
                'line-width': 2,
              },
            },
            // vertex point halos
            {
              id: 'gl-draw-polygon-and-line-vertex-halo-active',
              type: 'circle',
              filter: [
                'all',
                ['==', 'meta', 'vertex'],
                ['==', '$type', 'Point'],
              ],
              paint: {
                'circle-radius': 5,
                'circle-color': '#FFF',
              },
            },
            // vertex points
            {
              id: 'gl-draw-polygon-and-line-vertex-active',
              type: 'circle',
              filter: [
                'all',
                ['==', 'meta', 'vertex'],
                ['==', '$type', 'Point'],
              ],
              paint: {
                'circle-radius': 3,
                'circle-color': 'black',
              },
            },

            // INACTIVE
            // line stroke
            {
              id: 'gl-draw-line-inactive',
              type: 'line',
              filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['==', 'active', 'false'],
              ],
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
              paint: {
                'line-color': 'yellow',
                'line-width': 3,
              },
            },
            // polygon fill
            {
              id: 'gl-draw-polygon-fill-inactive',
              type: 'fill',
              filter: [
                'all',
                ['==', '$type', 'Polygon'],
                ['==', 'active', 'false'],
              ],
              paint: {
                'fill-color': '#000',
                'fill-outline-color': '#000',
                'fill-opacity': 0.1,
              },
            },
            // polygon outline
            {
              id: 'gl-draw-polygon-stroke-inactive',
              type: 'line',
              filter: [
                'all',
                ['==', '$type', 'Polygon'],
                ['==', 'active', 'false'],
              ],
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
              paint: {
                'line-color': 'yellow',
                'line-width': 3,
              },
            },
          ],
        });
        mapbox.map.on('draw.create', function(e) {
          _this.drawPolygon = e.features[0];
          //get trees from database with this poly
          _this.getSelectedPolygon();

          //_this.useBounds = false;
        });
        mapbox.map.on('draw.delete', function(e) {
          let fc = mapbox.draw.getAll();
          console.log(fc.features);
          if (fc.features.length === 0) {
            _this.useBounds = true;
            _this.drawPolygon = '';
            //clear selection
            let geo = {
              type: 'FeatureCollection',
              features: [],
            };
            _this.$store.commit('updateSelectedTreesGeoJson', geo);
          }
        });
        mapbox.map.on('moveend', async function() {
          await _this.getMapBoundingBox();
          await _this.updateTreesLayer();
        });
        mapbox.map.addControl(mapbox.draw, 'top-left');
        mapbox.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

        mapbox.map.addLayer({
          id: 'projectBoundary',
          type: 'line',
          source: 'project-boundary',
          paint: {
            'line-width': 2,
            'line-color': '#ff0000',
          },
        });

        // mapbox.map.addLayer({
        //   id: 'geojsonLayer2',
        //   type: 'line',
        //   source: 'project-boundary',
        //   paint: {
        //     'line-width': 2,
        //     'line-color': '#fff',
        //     'line-dasharray': [1, 1],
        //   },
        // });
        _this.getMapBoundingBox();

        _this.addTreeLayerAndSource();
        _this.addSelectedTreesLayer();

        //handle zoom if awaiting since map is created new each load
        if (_this.goToLatLon) {
          _this.flyToLatLon(_this.goToLatLon);
          _this.$store.commit('updateGoToLatLon', '');
        }
      });
      mapbox.map.on('click', 'trees-layer', async (e) => {
        this.mapClick(e);
      });
      mapbox.map.on('touchend', 'trees-layer', async (e) => {
        this.mapClick(e);
      });
      // Create a popup, but don't add it to the map yet.
      mapbox.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      mapbox.map.on('mouseenter', 'trees-layer', (e) => {
        // Change the cursor style as a UI indicator.
        mapbox.map.getCanvas().style.cursor = 'pointer';
      });

      mapbox.map.on('mouseleave', 'trees-layer', () => {
        mapbox.map.getCanvas().style.cursor = '';
      });
      // var Draw = new MapboxDraw({
      //   displayControlsDefault: false,
      //   // Select which mapbox-gl-draw control buttons to add to the map.
      //   controls: {
      //     polygon: true,
      //     trash: true,
      //   },
      // });
      mapbox.map.on('moveend', async function() {
        await _this.getMapBoundingBox();
        await _this.updateTreesLayer();
      });
      // mapbox.map.addControl(Draw, 'top-left');
      mapbox.map.resize();
      // map.on('load', function() {
      //   map.on('draw.create', console.log('draw create'));
      //   map.on('draw.delete', console.log('drew delete'));
      //   map.on('draw.update', console.log('draw update'));
      // });
    },
    async getMapBoundingBox() {
      return new Promise(async (resolve, reject) => {
        try {
          let boundingBoxString = `${
            mapbox.map.getBounds().getNorthWest().lat
          } ${mapbox.map.getBounds().getNorthWest().lng}, ${
            mapbox.map.getBounds().getNorthEast().lat
          } ${mapbox.map.getBounds().getNorthEast().lng}, ${
            mapbox.map.getBounds().getSouthEast().lat
          } ${mapbox.map.getBounds().getSouthEast().lng}, ${
            mapbox.map.getBounds().getSouthWest().lat
          } ${mapbox.map.getBounds().getSouthWest().lng}, ${
            mapbox.map.getBounds().getNorthWest().lat
          } ${mapbox.map.getBounds().getNorthWest().lng}`;

          // set bounding box to state
          await this.$store.commit('updateBoundingBox', boundingBoxString);
          await this.$store.dispatch('getTreesByMapExtent');
          resolve();
        } catch {
          reject();
        }
      });
    },
    addTreeLayerAndSource() {
      mapbox.map.addSource('trees-data', {
        type: 'geojson',
        data: this.treesData,
        generateId: true,
      });
      mapbox.map.addLayer({
        id: 'trees-layer',
        type: 'circle',
        source: 'trees-data',
        paint: {
          'circle-radius': 8,
          'circle-color': [
            'match', // Use the match expression
            ['get', 'symbology'], // Get the value of the "type" property
            1,
            'red', // If the "type" value is "Type A", use a red color
            0,
            'blue', // If the "type" value is "Type B", use a green color
            'green', // If the "type" value is,
            // 'case',
            // ['boolean', ['feature-state', 'hover'], false],
            // 'yellow',
            // 'red',
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
    },
    async updateTreesLayer() {
      var source = mapbox.map.getSource('trees-data');
      source.setData(this.treesData);
    },
    clearHighlight() {
      if (typeof mapbox.map.getLayer('selected-tree') !== 'undefined') {
        mapbox.map.removeLayer('selected-tree');
        mapbox.map.removeSource('selected-tree');
      }
    },
    highlightPoint(feature) {
      mapbox.map.addSource('selected-tree', {
        type: 'geojson',
        data: feature,
      });
      mapbox.map.addLayer({
        id: 'selected-tree',
        type: 'circle',
        source: 'selected-tree',
        paint: {
          'circle-radius': 8,
          'circle-opacity': 0,
          'circle-stroke-width': 3,
          'circle-stroke-color': '#000',
        },
      });
    },
    async mapClick(e) {
      let treeId = e.features[0].properties.tree_id;
      let projectId = null;
      let features = mapbox.map.queryRenderedFeatures(e.point, {
        layers: ['trees-layer'],
      });
      if (e.features[0].properties.symbology == 1) {
        projectId = parseInt(this.$router.currentRoute.value.params.index);
      }

      await this.$store.dispatch('getTreeById', [treeId, projectId]);
      this.clearHighlight();
      let feature = features[0];
      this.highlightPoint(feature);
      if (this.$q.screen.lt.sm) {
        this.$router.push(
          '/dashboard/project/' + projectId + '/tree/' + treeId
        );
      } else {
        this.$store.commit('updateShowTreeHome', true);
      }
      //this.$router.push('/dashboard/project/' + projectId + '/tree/' + treeId);
    },
    addSelectedTreesLayer() {
      mapbox.map.addSource('add-trees', {
        type: 'geojson',
        data: this.addSelectedTrees,
        generateId: true,
      });
      mapbox.map.addLayer({
        id: 'add-trees',
        type: 'circle',
        source: 'add-trees',
        paint: {
          'circle-radius': 8,
          'circle-color': '#fff',
          'circle-opacity': 0,
          'circle-stroke-width': 2,
          'circle-stroke-color': [
            'match', // Use the match expression
            ['get', 'symbology'], // Get the value of the "type" property
            3,
            'yellow', // If the "type" value is "Type A", use a red color
            2,
            'yellow', // If the "type" value is "Type B", use a green color
            'orange', // If the "type" value is,
            // 'case',
            // ['boolean', ['feature-state', 'hover'], false],
            // 'yellow',
            // 'red',
          ],
        },
      });
    },
    async getSelectedPolygon() {
      return new Promise(async (resolve, reject) => {
        try {
          let fc = mapbox.draw.getAll();
          let feat = fc.features[0];
          let poly = '';
          for (let i = 0; i < feat.geometry.coordinates[0].length; i++) {
            console.log(feat.geometry.coordinates[0][i][0]);
            poly =
              poly +
              feat.geometry.coordinates[0][i][1] +
              ' ' +
              feat.geometry.coordinates[0][i][0] +
              ',';
          }

          //remove last comma
          let newfeat = poly.substring(0, poly.length - 1);

          // set bounding box to state
          await this.$store.commit('updatePolygonString', newfeat);
          await this.$store.dispatch('getSelectedTreesByPoly');
          resolve();
        } catch {
          reject();
        }
      });
    },
    clearSelectedTrees(symbology) {
      //symbology = (3)= clear trees in add list, (2)=clear trees in remove list,  (0)= clear all trees

      //clear selection
      let features = this.$store.state.treesStore.selectedTreesGeoJson;
      let newFeatures = [];
      console.log(features);
      if (symbology > 0) {
        newFeatures = features.features.filter((feat) => {
          return feat.properties.symbology !== symbology;
        });
      }
      let geo = {
        type: 'FeatureCollection',
        features: newFeatures,
      };
      this.$store.commit('updateSelectedTreesGeoJson', geo);
      //if no more selected remove features
      if (this.addTreeList.length == 0 && this.removeTreeList.length == 0)
        mapbox.draw.deleteAll();
    },
    async addTreesToProject() {
      let trees = this.addTreeList;
      let project = parseInt(this.$router.currentRoute.value.params.index);
      for (let i = 0; i < trees.length; i++) {
        await this.$store.dispatch('addTreeToProject', {
          projectId: project,
          treeId: trees[i].properties.tree_id,
        });
      }
      this.clearSelectedTrees(3);
      await this.getMapBoundingBox();
      await this.updateTreesLayer();
    },
    async removeTreesFromProject() {
      let trees = this.removeTreeList;
      let project = parseInt(this.$router.currentRoute.value.params.index);
      for (let i = 0; i < trees.length; i++) {
        await this.$store.dispatch('removeTreeFromProject', {
          projectId: project,
          treeId: trees[i].properties.tree_id,
        });
      }
      this.clearSelectedTrees(2);
      await this.getMapBoundingBox();
      await this.updateTreesLayer();
    },
    editProjectBoundary() {
      this.showSave = true;
      mapbox.map.setLayoutProperty('projectBoundary', 'visibility', 'none');
      let bboxArray = this.getProjectBoundingBox();
      let geojson = bboxArray[1];
      mapbox.draw.add(geojson.features[0]);
    },
    async saveProjectBoundary() {
      //update project boundary
      //get updated project boudary

      let projectId = parseInt(this.$router.currentRoute.value.params.index);
      let polyText = '';
      let fc = mapbox.draw.getAll();
      let feat = fc.features[0];
      console.log(feat.geometry.coordinates[0]);
      let poly = 'POLYGON((';
      console.log(feat.geometry.coordinates[0].length);
      for (let i = 0; i < feat.geometry.coordinates[0].length; i++) {
        console.log(feat.geometry.coordinates[0][i][0]);
        poly =
          poly +
          feat.geometry.coordinates[0][i][1] +
          ' ' +
          feat.geometry.coordinates[0][i][0] +
          ',';
      }

      console.log(poly);
      //remove last comma
      let newfeat = poly.substring(0, poly.length - 1);
      //close the string
      polyText = newfeat + '))';
      //loop through drawed polygon and put coords in string
      let obj = {
        projectId: projectId,
        polygonText: polyText,
      };
      await this.$store.dispatch('editProjectLocation', obj);
      mapbox.map.setLayoutProperty('projectBoundary', 'visibility', 'visible');
      //let bboxArray = this.getProjectBoundingBox();
      // let bbox = bboxArray[0];
      // let geojson = bboxArray[1];
      // mapbox.map.fitBounds(bbox, { padding: 20 });
      let source = mapbox.map.getSource('project-boundary');
      source.setData(fc);
      mapbox.draw.deleteAll();

      this.showSave = false;
    },
    cancelProjectBoundary() {
      mapbox.draw.deleteAll();
      mapbox.map.setLayoutProperty('projectBoundary', 'visibility', 'visible');
      this.showSave = false;
    },
    flyToLatLon(obj) {
      mapbox.map.flyTo({
        center: [obj.lon, obj.lat],
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
      let geojson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [obj.lon, obj.lat],
        },
        properties: {
          title: 'My Marker',
          description: 'This is a custom marker',
        },
      };
      this.clearHighlight();
      this.highlightPoint(geojson);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="mapbox-gl/dist/mapbox-gl.css"></style>
<style scoped>
#map {
  display: flex;
}
.outlineToggle {
  border: 1px solid green;
}
</style>
