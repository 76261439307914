<template>
  <q-scroll-area class="scroll-height">
    <div class="wrapper" v-if="authMode == 'login'">
      <div class="q-mb-lg">
        <div class="loginHeader">Login</div>
        <div class="loginSubText">Please sign in to continue.</div>

        <hr />

        <div class="migration-annoucement">
          i-Tree is now hosting Healthy Trees, Healthy Cities.
        </div>
        <div class="migration-warning">
          For security, passwords cannot be transferred between systems.
        </div>
        <div class="migration-annoucement">
          You must reset your password; please select
          <span class="forgot-pass" @click="authMode = 'forgot'">Forgot</span>
          and follow the steps to regain access to your existing account.
        </div>
      </div>

      <q-input
        color="secondary"
        outlined
        v-model="username"
        label="Email"
        type="email"
        v-on:keyup.enter="login"
      >
        <template v-slot:prepend>
          <q-icon name="email" />
        </template>
      </q-input>
      <br />

      <q-input
        color="secondary"
        outlined
        v-model="password"
        label="Password"
        type="password"
        lazy-rules
        :rules="this.passwordValidation()"
        v-on:keyup.enter="login"
      >
        <template v-slot:prepend>
          <q-icon name="lock" />
        </template>
        <template v-slot:append>
          <q-btn
            flat
            style="font-weight: bold"
            color="primary"
            label="FORGOT"
            @click="authMode = 'forgot'"
          />
        </template>
      </q-input>
      <q-btn
        flat
        color="primary"
        size="sm"
        @click="
          this.showPasswordCriteria
            ? (this.showPasswordCriteria = false)
            : (this.showPasswordCriteria = true)
        "
        >{{ this.showPasswordCriteria ? 'Hide' : 'Show' }} Password
        Criteria</q-btn
      >
      <q-slide-transition>
        <div class="password-criteria q-pa-sm" v-if="showPasswordCriteria">
          <div class="text-subtitle2 q-mb-sm">Password Criteria:</div>
          <div>
            <q-icon
              :name="checkPassword.length ? 'check_circle' : 'cancel'"
              :color="checkPassword.length ? 'green' : 'red'"
            ></q-icon>
            Must be at least 8 characters long.
          </div>
          <div>
            <q-icon
              :name="checkPassword.capital ? 'check_circle' : 'cancel'"
              :color="checkPassword.capital ? 'green' : 'red'"
            ></q-icon>
            Must contain at least one capital letter.
          </div>
          <div>
            <q-icon
              :name="checkPassword.lower ? 'check_circle' : 'cancel'"
              :color="checkPassword.lower ? 'green' : 'red'"
            ></q-icon>
            Must contain at least one lowercase letter.
          </div>
          <div>
            <q-icon
              :name="checkPassword.number ? 'check_circle' : 'cancel'"
              :color="checkPassword.number ? 'green' : 'red'"
            ></q-icon>
            Must contain at least one number.
          </div>
          <div>
            <q-icon
              :name="checkPassword.symbol ? 'check_circle' : 'cancel'"
              :color="checkPassword.symbol ? 'green' : 'red'"
            ></q-icon>
            Must contain at least one symbol.
          </div>
        </div>
      </q-slide-transition>
      <div
        class="validation-error-text"
        v-if="this.$store.state.authStore.loginError == 'incorectEmailPassword'"
      >
        *You have entered an incorrect email or password. Please try again.
      </div>
      <div
        class="validation-error-text"
        v-if="this.$store.state.authStore.loginError == 'newPasswordRequired'"
      >
        New password required. Use
        <q-btn
          flat
          style="font-weight: bold"
          color="primary"
          label="forgot password"
          @click="authMode = 'forgot'"
        />
        form to proceed.
      </div>
      <div
        class="validation-error-text"
        v-if="this.$store.state.authStore.loginError == 'emailNotConfirmed'"
      >
        You have an account but have not confirmed your email address.
        <span class="confirm-email-button" @click="authMode = 'resendConfirm'"
          >Confirm Email</span
        >
      </div>
      <div class="row justify-end">
        <q-btn
          @click="login"
          size="18px"
          color="primary"
          class="q-mb-xs q-mt-lg"
          >Login</q-btn
        >
      </div>
      <div class="no-account-wrapper">
        Don't have an account? <span class="sign-up-starter" @click="authMode = 'signup'">Sign Up</span>
      </div>
    </div>

    <div class="wrapper" v-if="authMode == 'resendConfirm'">
      <div class="q-mb-lg">
        <div class="loginHeader">Confirm Email</div>
        <div class="loginSubText">Please confirm your email address.</div>
      </div>
      <q-input
        color="secondary"
        outlined
        v-model="username"
        label="Email"
        type="email"
      >
        <template v-slot:prepend>
          <q-icon name="email" />
        </template>
      </q-input>
      <div class="row justify-end">
        <q-btn
          @click="resendConfirm"
          size="18px"
          icon-right="login"
          color="primary"
          class="q-mb-xs q-mt-lg"
          >Resend Confirmation Code</q-btn
        >
      </div>
    </div>

    <div class="wrapper" v-if="authMode == 'signup'">
      <div class="q-mb-lg">
        <div class="loginHeader">Create Account</div>
        <div class="loginSubText">Create an account to contribute.</div>
      </div>
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="username"
        label="Email"
        type="email"
      >
        <template v-slot:prepend>
          <q-icon name="email" />
        </template>
      </q-input>

      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="givenName"
        label="Given Name"
        type="text"
      >
        <template v-slot:prepend>
          <q-icon name="person" />
        </template>
      </q-input>
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="familyName"
        label="Family Name"
        type="text"
      >
        <template v-slot:prepend>
          <q-icon name="person" />
        </template>
      </q-input>
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="organization"
        label="Organization"
        type="text"
      >
        <template v-slot:prepend>
          <q-icon name="home" />
        </template>
      </q-input>
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="createPassword"
        label="Password"
        type="password"
        lazy-rules
        :rules="this.createPasswordValidation()"
      >
        <template v-slot:prepend>
          <q-icon name="lock" />
        </template>
      </q-input>
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="createPasswordConfirm"
        label="Confirm Password"
        type="password"
      >
        <template v-slot:prepend>
          <q-icon name="lock" />
        </template>
      </q-input>
      <div
        class="validation-error-text"
        v-if="this.$store.state.authStore.signupError == 'accountAlreadyExists'"
      >
        *An account with this email already exists.
      </div>
      <div
        class="validation-error-text"
        v-if="this.$store.state.authStore.signupError == 'accountUsernameEmail'"
      >
        *Could not register email. Please, review email and try again.
      </div>
      <div
        class="validation-error-text"
        v-if="this.$store.state.authStore.signupError == 'accountUnknownError'"
      >
        *Please, review account information and try again.
      </div>
      <div class="password-criteria q-pa-sm">
        <div class="text-subtitle2 q-mb-sm">Password Criteria:</div>
        <div>
          <q-icon
            :name="checkCreatePassword.match ? 'check_circle' : 'cancel'"
            :color="checkCreatePassword.match ? 'green' : 'yellow'"
          ></q-icon>
          Must match.
        </div>
        <div>
          <q-icon
            :name="checkCreatePassword.length ? 'check_circle' : 'cancel'"
            :color="checkCreatePassword.length ? 'green' : 'red'"
          ></q-icon>
          Must be at least 8 characters long.
        </div>
        <div>
          <q-icon
            :name="checkCreatePassword.capital ? 'check_circle' : 'cancel'"
            :color="checkCreatePassword.capital ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one capital letter.
        </div>
        <div>
          <q-icon
            :name="checkCreatePassword.lower ? 'check_circle' : 'cancel'"
            :color="checkCreatePassword.lower ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one lowercase letter.
        </div>
        <div>
          <q-icon
            :name="checkCreatePassword.number ? 'check_circle' : 'cancel'"
            :color="checkCreatePassword.number ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one number.
        </div>
        <div>
          <q-icon
            :name="checkCreatePassword.symbol ? 'check_circle' : 'cancel'"
            :color="checkCreatePassword.symbol ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one symbol.
        </div>
      </div>
      <div class="row justify-end">
        <q-btn
          :disabled="!checkCreateAccount"
          @click="accountCreate"
          size="18px"
          color="primary"
          icon-right="login"
          class="q-mb-xs q-mt-md"
          :rules="this.createAccountValidation()"
          >Sign Up</q-btn
        >
      </div>

      <div class="no-account-wrapper">
        Already have an account?
        <span class="sign-in-starter" @click="authMode = 'login'">Sign In</span>
      </div>
    </div>

    <div class="wrapper" v-if="authMode == 'verify'">
      <div class="q-mb-lg">
        <div class="loginHeader">Verify Account</div>
        <div class="loginSubText">Check email to get verification code.</div>
      </div>
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        style="width: 60vw"
        v-model="verifyCode"
        label="Verify Account"
        type="text"
      ></q-input>
      <q-btn
        @click="confirmSignup"
        size="18px"
        color="primary"
        class="q-mb-xs q-mt-md"
        >Verify Account</q-btn
      >
      <div class="no-account-wrapper">
        Did not get a code? <span @click="resendAuthCode">Resend</span>
      </div>
    </div>

    <div class="wrapper" v-if="authMode == 'forgot'">
      <p class="text-h6">Enter email address associated with your account:</p>
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="username"
        label="Email"
        type="email"
      >
        <template v-slot:prepend>
          <q-icon name="email" />
        </template>
      </q-input>
      <q-btn
        @click="forgotPassword()"
        size="18px"
        color="primary"
        class="q-mb-xs q-mt-md"
        >Send Verify Code</q-btn
      >
    </div>

    <div class="wrapper" v-if="authMode == 'verifyForgot'">
      <q-input
        color="secondary"
        class="q-mb-md"
        outlined
        v-model="forgotPasswordConfirm"
        label="Password Reset Code"
        type="text"
      ></q-input>

      <q-input
        color="secondary"
        outlined
        v-model="password"
        label="New Password"
        type="password"
        lazy-rules
        :rules="this.passwordValidation()"
      ></q-input>
      <div class="password-criteria q-pa-sm">
        <div class="text-subtitle2 q-mb-sm">Password Criteria:</div>
        <div>
          <q-icon
            :name="checkPassword.length ? 'check_circle' : 'cancel'"
            :color="checkPassword.length ? 'green' : 'red'"
          ></q-icon>
          Must be at least 8 characters long.
        </div>
        <div>
          <q-icon
            :name="checkPassword.capital ? 'check_circle' : 'cancel'"
            :color="checkPassword.capital ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one capital letter.
        </div>
        <div>
          <q-icon
            :name="checkPassword.lower ? 'check_circle' : 'cancel'"
            :color="checkPassword.lower ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one lowercase letter.
        </div>
        <div>
          <q-icon
            :name="checkPassword.number ? 'check_circle' : 'cancel'"
            :color="checkPassword.number ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one number.
        </div>
        <div>
          <q-icon
            :name="checkPassword.symbol ? 'check_circle' : 'cancel'"
            :color="checkPassword.symbol ? 'green' : 'red'"
          ></q-icon>
          Must contain at least one symbol.
        </div>
      </div>
      <q-btn
        @click="verifyForgotPassword()"
        size="18px"
        color="primary"
        class="q-mb-xs q-mt-md"
        >Submit</q-btn
      >
    </div>
  </q-scroll-area>
</template>

<script>
// import TestUserInfo from '../user/TestUserInfo.vue';
// import TestUserPhotos from '../user/TestUserPhotos.vue';
export default {
  data() {
    return {
      // login data
      username: '',
      password: '',
      loginError: false,
      loginErrorText: 'None',

      // create account data
      createPassword: '',
      createPasswordConfirm: '',
      createPasswordError: '',
      createPasswordConfirmError: '',

      givenName: '',
      familyName: '',
      organization: '',
      verifyCode: '',
      showPasswordCriteria: false,

      checkPassword: {
        length: false,
        capital: false,
        lower: false,
        number: false,
        symbol: false,
      },
      checkCreatePassword: {
        match: false,
        length: false,
        capital: false,
        lower: false,
        number: false,
        symbol: false,
      },
      checkCreateAccount: false,

      forgotPasswordConfirm: '',

      userToggle: 'user',
      testQueryData: null,
      // emailError: false,
      // passwordError: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.authStore.userInfo;
    },
    authMode: {
      get() {
        return this.$store.state.authStore.authMode;
      },
      set(value) {
        this.$store.state.authStore.authMode = value;
      },
    },
  },
  methods: {
    emailValidation() {},

    passwordValidation() {
      // Test length
      this.checkPassword.length = this.password.length >= 8;

      // Test capital
      this.checkPassword.capital = /^(?=.*[A-Z])/.test(this.password);

      // Test lowercase
      this.checkPassword.lower = /^(?=.*[a-z])/.test(this.password);

      // Test number
      this.checkPassword.number = /^(?=.*[0-9])/.test(this.password);

      // Test symbol
      this.checkPassword.symbol =
        /^(?=.*[!@#\$%\^&\*_\-=+?:;|_\]\[(){}.<>,\/\\~`'"])/.test(
          this.password
        );
    },
    createPasswordValidation() {
      this.checkCreatePassword.match = (
        this.createPassword === this.createPasswordConfirm
      );

      // Test length
      this.checkCreatePassword.length = this.createPassword.length >= 8;

      // Test capital
      this.checkCreatePassword.capital = /^(?=.*[A-Z])/.test(this.createPassword);

      // Test lowercase
      this.checkCreatePassword.lower = /^(?=.*[a-z])/.test(this.createPassword);

      // Test number
      this.checkCreatePassword.number = /^(?=.*[0-9])/.test(this.createPassword);

      // Test symbol
      this.checkCreatePassword.symbol =
        /^(?=.*[!@#\$%\^&\*_\-=+?:;|_\]\[(){}.<>,\/\\~`'"])/.test(
          this.createPassword
        );
    },
    createAccountValidation() {
      this.createPasswordValidation();
      this.checkCreateAccount = (
        this.username !== ''
        && Object.values(
          this.checkCreatePassword
        ).every(val => val === true)
      );
      return this.checkCreateAccount;
    },

    async queryTreesByBox() {
      const trees = await this.$store.dispatch('protectedApiRequest', {
        route: `test`,
        type: 'GET',
      });

      this.testQueryData = trees.data.data;
    },
    accountCreate() {
      // Double enforce it. The validation's state is used to enable the submit
      // button but this function could be called by other mechanisms, so do a
      // final check before dispatching.
      if (!this.createAccountValidation()) {
        // TODO: replace "alert" with UI element.
        alert('Please, review account information and try again.');
        return;
      }

      this.$store.dispatch('createAccount', {
        email: this.username,
        password: this.createPassword,
        givenName: this.givenName,
        familyName: this.familyName,
        organization: this.organization,
      });
    },
    async login() {
      // run login vallidation here
      const login = await this.$store.dispatch('login', {
        email: this.username,
        password: this.password,
      });
    },
    logoutUser() {
      this.$store.dispatch('logoutUser');
    },
    resendConfirm() {
      this.$store.dispatch('resendConfirmationCode', this.username);
    },
    confirmSignup() {
      this.$store.dispatch('confirmSignup', {
        email: this.username,
        verifyCode: this.verifyCode,
      });
    },
    resendAuthCode() {
      console.warn('TODO: resend auth code. Current code:', this.verifyCode);
    },
    async forgotPassword() {
      await this.$store.dispatch('forgotPassword', this.username);
    },
    async verifyForgotPassword() {
      let obj = {
        email: this.username,
        newPassword: this.password,
        verifyCode: this.forgotPasswordConfirm,
      };
      await this.$store.dispatch('confirmForgotPassword', obj);
    },
    getCurrentUserSession() {
      this.$store.dispatch('getCurrentUserSession');
    },
    exampleApiRequest() {
      this.$store.state.userStore.userInfo = '';
      this.$store.dispatch('getUserInfo');
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 20px;
  text-align: left;
  overflow: auto;
}
.scroll-height {
  height: calc(100vh - 20px);
}
.loginHeader {
  font-size: 40px;
  font-weight: bold;
}
.migration-annoucement,
.migration-warning,
.loginSubText {
  font-size: 20px;
  font-weight: bold;
}
.loginSubText {
  color: darkgray;
}
.migration-annoucement {
  color: #444444;
}
.migration-warning {
  color: #a94442;
}
.no-account-wrapper {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}
.no-account-wrapper span {
  font-weight: bold;
  font-size: 22px;
  color: var(--q-primary) !important;
}
.forgot-pass {
  color: var(--q-primary) !important;
}
.forgot-pass-btn {
  font-weight: bold;
  font-size: 16px;
  color: var(--q-primary) !important;
}
.validation-error-text {
  color: red;
}
.confirm-email-button {
  color: blue;
  text-decoration: underline;
  font-weight: bold;
}
.confirm-email-button:hover {
  cursor: pointer;
}
.forgot-pass,
.sign-in-starter,
.sign-up-starter {
  cursor: pointer;
}
</style>
